//FIXME we could move this into constructor 
// and switch it to WS 

import {untilTrue,COMMON,DEBUG} from '../common.js';
import DEFAULT_FAVICON from '../subviews/faviconDataURL.js';

const STATE_SYMBOL = Symbol(`[[State]]`);
const MODAL_MESSAGE = `RBI已暂停，可能是因为有模态对话框打开。没关系，这有时会发生。在离开页面之前，请记得关闭任何模态对话框。您也可以再次尝试关闭最后一个打开的模态对话框。`;
let modaler;
let active;

export async function fetchTabs({sessionToken}, getState) {
  DEBUG.debugTabs && console.log(`Fetch tabs called`);
  try {
    const url = new URL(location);
    url.pathname = '/api/v1/tabs';
    const resp = await fetch(url);
    if ( resp.ok ) {
      const data = await resp.json();
      if ( data.error ) {
        if ( data.resetRequired ) {
          const reload = confirm(`出现了一些错误，我们似乎无法连接到您的云浏览器。您可以尝试重新加载页面，如果问题仍然存在，请尝试关闭再打开云浏览器。现在要重新加载页面吗？`);
          if ( reload ) location.reload();
        }
      } 
      if ( data.vmPaused ) {
        let {modal} = data;
        DEBUG.debugModal && console.log('Remote Browser VM is paused. Probably because a modal dialog is open.');  
        if (modal) {
          if ( ! active ) {
            clearTimeout(modaler);
            modaler = setTimeout(async () => {
              if ( active ) return;
              active = true;
              const state = getState();
              DEBUG.debugModal && console.log({modal, 1:true});
              await untilTrue(() => !!state?.viewState?.modalComponent, 100, 1000);
              DEBUG.debugModal && console.log({modal, 2:true});
              state.viewState.modalComponent.openModal({modal});
              DEBUG.debugModal && console.log({modal, 3:true});
              await untilTrue(() => !state?.viewState?.currentModal, 100, 1000).then(() => {
                active = false;
                DEBUG.debugModal && console.log('Active is now false.');
              });
              DEBUG.debugModal && console.log({modal, 4:true});
            }, 0);
          }
        }
      }
      DEBUG.debugBetterModals && console.log(data);
      if ( data.tabs ) {
        data.tabs = (data.tabs || []).filter(({type}) => type == 'page');
        data.tabs = data.tabs.map(tab => new Tab(tab, {getState}));
      }
      DEBUG.debugTabs && console.log(data);
      return data;
    } else if ( resp.status == 401 ) {
      console.warn(`Session has been cleared. Let's attempt relogin`, sessionToken);
      const x = new URL(location);
      x.pathname = 'login';
      x.search = `token=${sessionToken}&ran=${Math.random()}`;
      COMMON.blockAnotherReset = true;
      alert("您的浏览器清除了您的会话。我们需要重新加载页面来刷新它。");
      COMMON.delayUnload = false;
      location.href = x;
      return;
    }
  } catch(e) {
    console.warn(e);
    alert(e);
    const reload = confirm(`出现了一些错误，我们似乎无法连接到您的云浏览器。您可以尝试重新加载页面，如果问题仍然存在，请尝试关闭再打开云浏览器。现在要重新加载页面吗？`);
    if ( reload ) location.reload();
  }
}

class Tab {
  constructor({...tabDetails}, {getState} = {}) {
    Object.assign(this, tabDetails);
    Object.defineProperty(this, STATE_SYMBOL, {
      get: () => getState()
    });
  }

  setDefaultFavicon() {
    this.faviconDataURI = DEFAULT_FAVICON;
    this.usingDefaultFavicon = true;
  }

  get faviconDataURI() {
    return this[STATE_SYMBOL].favicons.get(this.targetId)?.dataURI; 
  }

  set faviconDataURI(dataURI) {
    this[STATE_SYMBOL].favicons.set(
      this.targetId,
      Object.assign(
        this[STATE_SYMBOL].favicons.get(this.targetId) || {}, 
        {dataURI}
      )
    );
    this.usingDefaultFavicon = false;
  }

  get favicon() {
    return this[STATE_SYMBOL].favicons.get(this.targetId)?.favicon; 
  }

  set favicon(favicon) {
    this[STATE_SYMBOL].favicons.set(
      this.targetId,
      Object.assign(
        this[STATE_SYMBOL].favicons.get(this.targetId) || {}, 
        {favicon}
      )
    );
  }
}
